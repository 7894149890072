<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{ $t('Observers') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('Survey Name*')" label-for="project_name">
                                                <b-form-select 
                                                v-model="selectedProjectId" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null" 
                                                aria-describedby="input-1-live-feedback" 
                                                :options="projectOptions" 
                                                value-field="id" 
                                                text-field="name"
                                                @change="onProjectChange">
                                                <b-form-select-option v-if="projectOptions.length === 0" :disabled="true">
                                                    {{ $t('No Project Found') }}
                                                </b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('survey-style*')" label-for="survey_style_name">
                                                <b-form-input
                                                type="text"
                                                id="survey_style_name"
                                                name="survey_style_name"
                                                :placeholder="$t('survey-style*')"
                                                v-model="form.survey_style_name" 
                                                readonly>
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <!--  <div class="col-md-4">
                                            <b-form-group :label="$t('project-id*')" label-for="project_id">
                                            <b-form-input
                                                type="text"
                                                id="project_id"
                                                name="project_id"
                                                autocomplete="new-password"
                                                :placeholder="$t('project-id*')"
                                                v-model="form.project_id" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null"
                                                readonly
                                                >
                                            </b-form-input>
                                            </b-form-group>
                                        </div>-->
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-md-2 mt-4">
                                                    <label for="positions">{{ $t('observer-position') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="positions">
                                                        <b-form-select 
                                                            v-model="form.position" 
                                                            aria-describedby="input-1-live-feedback" 
                                                            :options="positions" 
                                                            value-field="id" 
                                                            text-field="name">
                                                            <b-form-select-option v-if="positions.length === 0" :disabled="true">
                                                                {{ $t('No Position Found') }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-4 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('positions')">{{  $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2 mt-4">
                                                    <label for="observers">{{$t('Observers')}}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="observers">
                                                        <b-form-select 
                                                        v-model="form.observer" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="observers" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="observers.length === 0" :disabled="true">
                                                            {{ $t('No Observer Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-4 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('observers')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Aerial Survey' || form.is_NARW === 1">
                                               <!-- <div class="col-md-2 mt-4">
                                                    <label for="observerPosition">{{ $t('observer-position') }}</label>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="observerPosition">
                                                        <b-form-select 
                                                            v-model="form.position" 
                                                            aria-describedby="input-1-live-feedback" 
                                                            :options="observerPosition" 
                                                            value-field="id" 
                                                            text-field="name">
                                                            <b-form-select-option v-if="observerPosition.length === 0" :disabled="true">
                                                                {{ $t('no-observer-position-found') }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-4 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('observerPosition')">{{  $t('edit-values') }}</b-button>
                                                </div>-->
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <section>
                                                        <vue-tags-input
                                                            :tags="tags"
                                                            @tags-changed="tags = $event"
                                                            :allow-edit-tags="true"
                                                            class="tags-input"
                                                            :placeholder="$t('Enter values as tags')"
                                                            :add-only-from-autocomplete="false"
                                                        />
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button type="submit" variant="success" :disabled="isDisabled" class="btn float-right btn-success">
                                                <span>{{ $t('submit-btn') }}</span>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</template>


<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, survey_style, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    import VueTagsInput from '@johmun/vue-tags-input';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        components: {
            VueTagsInput,
        },
        data() {
            return {
                form: {
                    id: '',
                    project_name: '',
                    project_id: '',
                    survey_style_id: '',
                    is_NARW: null,
                    survey_style_name: '',
                    category_name:'',
                    spot_type_name:'',
                    position:'',
                    // observerPosition:'',
                    observer: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                isDisabled: false,
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                orders: [],
                projectOptions: [],
                categories: [],
                spotTypes: [],
                observers: [],
                positions: [],
                // observerPosition: [],
                selectedProjectId: null,
                showInput: false,
                inputValue: '',
                currentField: '',
                tags: [],
            };
        },

        validations: {
            form: {
                id: {},
                project_name: {
                    required,
                }, 
                project_id: {
                    required,
                },
                survey_style_id: {
                    required,
                },
            },
        },
        mounted() {
            this.form.id = this.$route.params.id;
            if (this.form.id && this.form.id !== 'undefined') {
                this.readonly = true;
                this.getSpotter();
            } else {
                this.readonly = false;
            }
            this.isLoading = true;
            // this.inputValue = this.categoriesName;
            this.getNames();
            // this.getSpotType();
            // this.getCategories();
        },
        methods: {
            toggleInput(field) {
                if (this.currentField === field && this.tags.length > 0) {
                    return; // Avoid overwriting when the same field is re-selected
                }

                this.currentField = field;

                // Safely map data to tags, ensuring field exists
                const fieldData = this[field] || [];
                this.tags = fieldData.map(item => ({ text: item.name || item })); // Handle undefined `name`
            },
            onSubmit() {

                if (!this.form.project_id) {
                    this.isDisabled = true;
                    this.$snotify.warning('Please fill in the project name.', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                let data = {
                    project_id: this.selectedProjectId,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                if (this.currentField === 'positions') {
                    data.position = this.inputValue;
                } 
                else if (this.currentField === 'observers') {
                    data.observer = this.inputValue;
                } 
                // else if (this.currentField === 'observerPosition') {
                //     data.observerPosition = this.inputValue;
                // } 
                API.addEditProjectSetup(
                    data,
                    (data) => {
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.inputValue = '';
                        this.currentField = '';
                        this.getProjectSetupData(this.selectedProjectId);
                    },
                );
            },
            getNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                    this.projectOptions = response.data.map(project => ({
                        id: project.id,
                        name: project.project_name
                    }));
                        if (this.projectOptions.length > 0) {
                            this.selectedProjectId = this.projectOptions[0].id;
                            this.getProjectStyleData(this.selectedProjectId);
                            this.getProjectSetupData(this.selectedProjectId);
                            this.toggleInput();
                        }
                    },
                    err => {
                    console.error('Error fetching project names:', err);
                    }
                );
            },
            getProjectStyleData(projectId) {
                API.getProjectSetup(
                    { projectId },
                    response => {
                        const project = response.data;
                        this.form.project_id = project.project_id;
                        this.form.survey_style_id = project.survey_style_id;
                        this.form.is_NARW = project.is_NARW;
                        this.form.survey_style_name = project.survey_style ? project.survey_style.name : '';
                    },
                    err => {
                    console.error('Error fetching project details:', err);
                    }
                );
            },
            getProjectSetupData(projectId) {
                API.getProjectSetupDetails({ projectId }, response => {
                    const projects = response.data;
                    if (projects.length > 0) {
                        this.positions = projects.filter(project => project.position_name).map(project => ({ id: project.id, name: project.position_name }));
                        this.observers = projects.filter(project => project.observer_name).map(project => ({ id: project.id, name: project.observer_name }));
                        // this.observerPosition = projects.filter(project => project.observer_position).map(project => ({ id: project.id, name: project.observer_position }));
                        this.form.position = this.positions[0]?.id || null;
                        this.form.observer = this.observers[0]?.id || null;
                        // this.form.observerPosition = this.observerPosition[0]?.id || null;
                    } else {
                        this.positions = [];
                        this.observers = [];
                        // this.observerPosition = [];
                        this.form.position = null;
                        this.form.observer = null;
                        // this.form.observerPosition = null;
                    }
                }, err => {
                    console.error('Error fetching project details:', err);
                });
            },
            onProjectChange(selectedProjectId) {
                if (selectedProjectId) {
                    this.getProjectStyleData(selectedProjectId);
                    this.getProjectSetupData(selectedProjectId);
                    this.toggleInput();
                }
            },

        }, 
        computed: {
            observersName: {
                get() {
                    return this.observers.map(observer => observer.name).join(', ');
                },
                set(newValue) {
                    const observers = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.observers.find(observer => observer.name === name.trim()).id };
                    });
                    this.form.observer = observers;
                }
            },
            positionsName: {
                get() {
                    return this.positions.map(position => position.name).join(', ');
                },
                set(newValue) {
                    const positions = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.positions.find(position => position.name === name.trim()).id };
                    });
                    this.form.position = positions;
                }
            },
            // observerPositionName: {
            //     get() {
            //         return this.observerPosition.map(observerPosition => observerPosition.name).join(', ');
            //     },
            //     set(newValue) {
            //         const observerPosition = newValue.split(',').map(name => {
            //             return { name: name.trim(), id: this.observerPosition.find(observerPosition => observerPosition.name === name.trim()).id };
            //         });
            //         this.form.observerPosition = observerPosition;
            //     }
            // }
        },
        watch: {
            tags: {
                handler(newTags) {
                    const formattedTags = (newTags || []).map(tag => tag.text || '');
                    if (this.currentField) {
                        this[this.currentField] = formattedTags;
                    }
                    this.inputValue = formattedTags.join(',');
                },
                deep: true,
            },
        },
    }
</script>

<style>
    .btn-duplicate {
        width: 80px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    .card-body.new-card-body select {
        border: 1px solid #000;
        border-radius: 0;
        height: 46px;
    }
    .card-body.new-card-body textarea {
        border: 1px solid #000 !important;
        height: 250px;
        outline: none;
        box-shadow: none;
        border-radius: 0;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
